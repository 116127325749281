import {Carousels} from "./Carousels";

Carousels('.wbsslider', {
    perPage: 1,
    arrows: false,
    mediaQuery: 'min',
    rewind: false,
    rewindByDrag: true,
    pagination: true,
    type: 'loop',
    autoplay: true,
})