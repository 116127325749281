import Splide from '@splidejs/splide';

export function Carousels(selector, config = {}, additionalOptions = {}) {
    if (!selector) {
        return;
    }


    document.addEventListener('DOMContentLoaded', function () {
        const carousels = document.querySelectorAll(selector);

        if (carousels.length > 0) {

            const defaultConfig = {
                perPage: 3, pagination: false, arrows: false, autoplay: false, mediaQuery: 'min',
            };


            const mergedConfig = {...defaultConfig, ...config};


            if (additionalOptions.hasOwnProperty('sizerClass')) {
                const padding = sizer(additionalOptions.sizerClass);

                mergedConfig.breakpoints[1024].padding = {
                    left: `${padding}px`, right: '5%'
                };
            }

            carousels.forEach(carousel => {
                const instance = new Splide(carousel, mergedConfig).mount();

                if (additionalOptions.hasOwnProperty('customArrow') && additionalOptions.customArrow && additionalOptions.hasOwnProperty('customArrowPrev') && additionalOptions.hasOwnProperty('customArrowNext')) {
                    const prevArrow = document.querySelector(additionalOptions.customArrowPrev);
                    const nextArrow = document.querySelector(additionalOptions.customArrowNext);

                    prevArrow.addEventListener('click', () => {
                        instance.go('<');
                    });

                    nextArrow.addEventListener('click', () => {
                        instance.go('>');
                    });
                }
            });


        }

    });

    function sizer(sizerClass) {
        const container = document.querySelector(sizerClass);

        const containerStyle = getComputedStyle(container);

        // Get container's max-width
        const containerMaxWidth = parseFloat(containerStyle.maxWidth);

        // Get container's left padding
        const containerPaddingLeft = parseFloat(containerStyle.paddingLeft);

        const viewportWidth = window.innerWidth;

        // Calculate the margin without the padding
        let containerMargin = (viewportWidth - containerMaxWidth) / 2;

        // Add the left padding to the calculated margin
        containerMargin += containerPaddingLeft;

        return containerMargin > 0 ? containerMargin : 0;
    }


}